import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "Category",
      "ComponentDataEntry",
      "ComponentDataNote",
      "ComponentDataNutrients",
      "ComponentDataProductFact",
      "ComponentDataRecipe",
      "ComponentDataSet",
      "ComponentDataStep",
      "I18NLocale",
      "Product",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "Category",
      "ComponentDataEntry",
      "ComponentDataNote",
      "ComponentDataNutrients",
      "ComponentDataProductFact",
      "ComponentDataRecipe",
      "ComponentDataSet",
      "ComponentDataStep",
      "I18NLocale",
      "Product",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      export default result;
    
export const FileFragmentDoc = gql`
    fragment File on UploadFileEntity {
  id
  attributes {
    previewUrl
    alternativeText
    url
  }
}
    `;
export const CategoryFragmentDoc = gql`
    fragment Category on CategoryEntity {
  id
  attributes {
    slug
    name
    isPremium
    isSystem
    cover {
      data {
        ...File
      }
    }
  }
}
    `;
export const EntryFragmentDoc = gql`
    fragment Entry on ComponentDataEntry {
  id
  key
  value
}
    `;
export const IngredientFragmentDoc = gql`
    fragment Ingredient on ProductEntity {
  id
  attributes {
    slug
    name
    image {
      data {
        ...File
      }
    }
  }
}
    `;
export const ProductFragmentDoc = gql`
    fragment Product on ProductEntity {
  id
  attributes {
    slug
    name
    image {
      data {
        ...File
      }
    }
    nutrients {
      calories
      carbs
      fat
      protein
    }
    recipe {
      title
      steps {
        description
        order
      }
    }
    ingredients {
      data {
        ...Ingredient
      }
    }
    categories {
      data {
        attributes {
          name
          slug
        }
      }
    }
    facts {
      name
      key
      value
    }
    notes {
      order
      description
      type
    }
  }
}
    `;
export const MeDocument = gql`
    query me {
  me {
    id
  }
}
    `;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const ProductsDocument = gql`
    query products {
  products(locale: "en") {
    data {
      ...Product
    }
  }
}
    ${ProductFragmentDoc}
${FileFragmentDoc}
${IngredientFragmentDoc}`;
export function useProductsQuery(baseOptions?: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
      }
export function useProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;