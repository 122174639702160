import { Col, Row, Typography } from "antd"
import { FC } from "react"
import { Link } from "react-router-dom"
import { useApp } from "../app"

const Footer: FC = () => {
  const { app } = useApp()
  return <div>I am a footer</div>
}

export { Footer }
